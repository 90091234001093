import React, { ReactElement, useEffect, useRef, useState } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import ApplicationCreate from '../../../components/Application/Create'
import useWindowSize from '../../../utils/sizeWindow/windowSizeHook'
import { changeVisibleDrawerA, createApplicationA } from '../actions'
import { isFetchingApplicationS, isVisibleDrawerCreateS } from '../selectors'

type ApplicationCreateContentProps = {
  isFetching: boolean
  isOpenDrawer: boolean

  createApplication: (data: any) => void
  changeVisibleDrawer: (value: boolean) => void
}

function ApplicationCreateContent({
  isFetching,
  isOpenDrawer,

  createApplication,
  changeVisibleDrawer,
}: ApplicationCreateContentProps): ReactElement {
  const [name, setName] = useState<string>('')
  const [pass, setPass] = useState<string>('')
  const [isDisabledSave, setIsDisabledSave] = useState<boolean>(true)

  const [imageData, setImageData] = useState<any>()

  const [docData, setDocData] = useState<any>()

  const size = useWindowSize()

  const firstField = useRef()

  if (
    R.isNil(sessionStorage.getItem('org_id'))
    || R.isEmpty(sessionStorage.getItem('org_id'))
  // eslint-disable-next-line react/jsx-no-useless-fragment
  ) return <></>

  const handleUploadImg = (files: any): void => {
    setImageData(files[0])
  }

  const handleUploadDoc = (data: any): void => {
    setDocData(R.isNil(data) ? undefined : data[0])
  }

  const handleOpenDrawer = (): void => {
    changeVisibleDrawer(true)
  }

  const handleCloseDrawer = (): void => {
    changeVisibleDrawer(false)
  }
  const handleChangeName = (value: any): void => {
    setName(value)
  }

  const handleChangePass = (value: any): void => {
    setPass(value)
  }

  const handleSave = (): void => {
    createApplication({
      name,
      pass_to_orgs: pass,
      file: imageData,
      claim_doc: docData,
    })
  }

  useEffect(() => {
    if (!isOpenDrawer) {
      setName('')
      setPass('')
      setIsDisabledSave(true)
      setImageData(undefined)
      setDocData(undefined)
    }
  }, [isOpenDrawer])

  useEffect(() => {
    if (
      !R.isEmpty(name)
      && !R.isEmpty(pass)
      && !R.isNil(imageData)
      && !R.isEmpty(imageData)
    ) {
      if (isDisabledSave) {
        setIsDisabledSave(false)
      }
    } else if (!isDisabledSave) {
      setIsDisabledSave(true)
    }
  }, [
    name,
    pass,
    imageData,
  ])

  useEffect(() => {
    if (localStorage.getItem('visibleDrawerCreate')) {
      localStorage.removeItem('visibleDrawerCreate')
      setTimeout(() => {
        handleOpenDrawer()
      }, 1000)
    }
  }, [])

  return (
    <ApplicationCreate
      name={name}
      pass={pass}
      size={size}
      firstField={firstField}
      handleSave={handleSave}
      isFetching={isFetching}
      isOpenDrawer={isOpenDrawer}
      isDisabledSave={isDisabledSave}
      handleUploadImg={handleUploadImg}
      handleUploadDoc={handleUploadDoc}
      handleChangePass={handleChangePass}
      handleChangeName={handleChangeName}
      handleOpenDrawer={handleOpenDrawer}
      handleCloseDrawer={handleCloseDrawer}
    />
  )
}

export default connect(
  (state: RootStateInterface) => ({
    isFetching: isFetchingApplicationS(state),
    isOpenDrawer: isVisibleDrawerCreateS(state),
  }),
  {
    changeVisibleDrawer: changeVisibleDrawerA,
    createApplication: createApplicationA.request,
  },
)(ApplicationCreateContent)

