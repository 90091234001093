import React, { useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import NotFound from '../modules/NotFound'

function Preview() {
  const navigator = useNavigate()
  // eslint-disable-next-line camelcase
  const { org_id } = useParams()
  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (+org_id) {
      localStorage.setItem('visibleDrawerCreate', true)
      sessionStorage.setItem('org_id', org_id)
    }
    navigator('/application/search')
  }, [])

  return null
}

const routes = [
  {
    title: '',
    path: '/',
    render: <Navigate
      to="/application/search"
    />,
    rights: {
      show: '',
      edit: '',
    },
    isPrivate: false,
    exact: true,
  },
  {
    title: '',
    path: '/:org_id',
    element: <Preview />,
    rights: {
      show: '',
      edit: '',
    },
    isPrivate: false,
    exact: true,
  },
  {
    title: '',
    path: '*',
    element: <NotFound />,
    rights: {
      show: '',
      edit: '',
    },
    isPrivate: false,
    exact: false,
  },
]

export default routes
