import { createReducer, getType } from '@reduxjs/toolkit'
import { createStandaloneToast } from '@chakra-ui/react';
import { notification } from './actions.ts';

const { toast } = createStandaloneToast()

const initialState = {
  error: false,
  notifications: [],
};

function removeNotification(state, action) {
  const id = action.payload;
  return state.notifications.filter((notify) => notify.id !== id);
}

function createNotification(type, message) {
  let text = '';
  if (typeof message === 'object') {
    try {
      text = message.message
    } catch {
      text = 'Неизвестаная ошибка'
    }
  } else {
    text = message
  }
  function getType(type) {
    switch (type) {
      case 'NOTIFICATION_SHOW':
        return 'show'
      case 'NOTIFICATION_SUCCESS':
        return 'success'
      case 'NOTIFICATION_ERROR':
        return 'error'
      case 'NOTIFICATION_WARNING':
        return 'warning'
      case 'NOTIFICATION_INFO':
        return 'info'
      case 'NOTIFICATION_CONFIRM':
        return 'confirm'
      default:
        return {}
    }
  }
  if (!text) {
    toast({
      duration: 5000,
      status: getType(type),
      position: 'bottom-right',
      title: text || '',
      containerStyle: {
        minWidth: '0',
      },
    })
  } else {
    toast({
      duration: 5000,
      isClosable: true,
      status: getType(type),
      position: 'bottom-right',
      containerStyle: {
        minWidth: '0',
        fontSize: '16px',
      },
      title: text || '',
    })
  }
}

const reducer = createReducer(initialState, {
  NOTIFICATION: (state, action) => {
    const { type, text } = action.payload;
    createNotification(type, text)
    return state
  },
  [getType(notification.show)]: (state, action) => {
    createNotification(action.type, action.payload.message || action.payload.text)
    return state
  },
  [getType(notification.error)]: (state, action) => {
    createNotification(action.type, action.payload.message || action.payload.text)
    return state
  },
  [getType(notification.success)]: (state, action) => {
    createNotification(action.type, action.payload.message || action.payload.text)
    return state
  },
  [getType(notification.warning)]: (state, action) => {
    createNotification(action.type, action.payload.message || action.payload.text)
    return state
  },
  [getType(notification.info)]: (state, action) => {
    createNotification(action.type, action.payload.message || action.payload.text)
    return state
  },
  [getType(notification.confirm)]: (state, action) => {
    createNotification(action.type, action.payload.message || action.payload.text)
    return state
  },

  NOTIFICATION_CONFIRM: (state, action) => {
    state.notifications = [
      ...state.notifications,
      createNotification(
        action.type,
        action.payload.message || action.payload.text,
        action.payload.confirm,
        action.payload.cancel,
      )]
      return state
  },
  NOTIFICATION_RESET: (state, action) => {
    state.notifications = removeNotification(state, action)
    return state
  },
})

export default reducer
