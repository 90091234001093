import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history';
import { globalHistory } from '@reach/router'
import { rootReducer as createRootReducer } from '../reducers/index.ts'
import rootSagas from '../sagas/index.ts'
import { errorHandler, notification } from '../middleware';

const {
  createReduxHistory,
  routerMiddleware,
} = createReduxHistoryContext({ history: createBrowserHistory(), reachGlobalHistory: globalHistory });

const devMode = process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: createRootReducer(),
  devTools: devMode,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({
    thunk,
    immutableCheck: false,
    serializableCheck: false,
  }), sagaMiddleware, notification, errorHandler, routerMiddleware],
});

sagaMiddleware.run(rootSagas);
export const history = createReduxHistory(store)

export default store;
