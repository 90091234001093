
export const en = {
  app_preloader: 'Loading',
  sign_out: 'Sign out',
  profile: 'Profile',
  date_picker_time: 'Time',
  today_desc: 'Today',
  cancel: 'Cancel',
  save: 'Save',
  version: 'Version',
  'filter.reset': 'Clear filter',
  'denied-message': 'ACCESS IS DENIED',
  not_found: 'Page Not Found',
  not_found_desc: 'The page you\'re looking for does not seem to exist',
  go_main: 'Go to Main page',
  req_fields: 'Fill in all required fields to send',
}

export const ru = {
  app_preloader: 'Загрузка',
  sign_out: 'Выйти',
  profile: 'Профиль',
  date_picker_time: 'Время',
  today_desc: 'Сегодня',
  cancel: 'Отменить',
  save: 'Сохранить',
  version: 'Версия',
  'filter.reset': 'Очистить фильтр',
  'denied-message': 'ДОСТУП ЗАПРЕЩЕН',
  not_found: 'Страница не найдена',
  not_found_desc: 'Страница, которую вы ищете, не существует',
  go_main: 'Вернуться на главную',
  req_fields: 'Заполните все обязательные поля для отправки',
}
