/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate, Route } from 'react-router-dom';

function PrivateRoute(properties) {
  const {
    isAuthenticated, element: Element, props,
  } = properties;
  const prepProps = { ...properties }

  return (
    <Route
      {...prepProps}
      element={isAuthenticated ? <Element {...props} /> : <Navigate to="/login" />}
    />
  )
}

export default PrivateRoute;
