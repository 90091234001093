import { createAsyncAction } from 'typesafe-actions';
import {
  FETCH_APP_VERSION_ERROR,
  FETCH_APP_VERSION_REQUEST,
  FETCH_APP_VERSION_SUCCESS,
  FETCH_USER_DATA_ERROR,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
} from './types';

export const getUserDataA = createAsyncAction(
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_ERROR,
)<undefined, any, Error>()

export const getAppVersionA = createAsyncAction(
  FETCH_APP_VERSION_REQUEST,
  FETCH_APP_VERSION_SUCCESS,
  FETCH_APP_VERSION_ERROR,
)<undefined, string, Error>()
