import { Map } from 'immutable'

/** Стэйт модуля */
export const applicationStateS = (state: RootStateInterface): Map<string, any> => state.application

/** флаг загрузки данных */
export const isFetchingApplicationS = (state: RootStateInterface): boolean => applicationStateS(state).get('isFetching')

/** Идентификатор поданной заявки */
export const applicationIDS = (state: RootStateInterface): string => applicationStateS(state).get('applicationID')

/** Заявка */
export const applicationInfoS = (state: RootStateInterface): any => applicationStateS(state).get('applicationInfo')

export const isVisibleDrawerCreateS = (state: RootStateInterface): boolean => applicationStateS(state).get('isVisibleDrawer')
