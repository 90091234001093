import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import * as R from 'ramda'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import ApplicationSearch from '../../../components/Application/Search'
import routsManager from '../../../routes'
import { applicationInfoA, dropApplicationInfoA } from '../../../actions'
import { applicationInfoS } from '../selectors'
import { statusList } from '../utils'

const rights = {
  show: '',
  edit: '',
}

type ApplicationSearchContentProps = {
  applicationInfo: any

  dropApplicationInfo: () => void
  getApplication: ({ value, runner }: any) => void
}

function ApplicationSearchContent({
  applicationInfo,

  getApplication,
  dropApplicationInfo,
}: ApplicationSearchContentProps): ReactElement {
  const [status, setStatus] = useState<any>({})

  const [searchValue, setSearchValue] = useState<string>('')

  const { id } = useParams()

  const handleChangeSearch = ({ target }: any): void => {
    setSearchValue(target.value)
  }

  let runner: any

  const search = useCallback(
    debounce((value) => {
      clearInterval(runner)
      getApplication({ value, runner: '' })
      runner = setInterval(() => {
        getApplication({ value, runner })
      }, 10000)
    }, 1000),
    [],
  )

  useEffect(() => {
    const handlePressEnter = (event: any) => {
      if (event.charCode === 13) {
        search(searchValue)
      }
    }
    if (!R.isEmpty(searchValue)) {
      window.addEventListener('keypress', handlePressEnter)
    } else {
      R.forEach((item) => clearInterval(item), R.range(0, 10000))
      dropApplicationInfo()
    }
    return () => {
      window.removeEventListener('keypress', handlePressEnter)
    }
  }, [searchValue, runner])

  useEffect(() => {
    if (!R.isEmpty(applicationInfo) && !R.isNil(applicationInfo?.status)) {
      setStatus(R.find(R.propEq('value', applicationInfo?.status))(statusList))
    }
  }, [applicationInfo])

  useEffect(() => {
    if (!R.isNil(id) && !R.isEmpty(id)) {
      search(id)
      setSearchValue(id)
    }
  }, [id])

  useEffect(() => {
    return (() => {
      clearInterval(runner)
      dropApplicationInfo()
    })
  }, [])

  return (
    <ApplicationSearch
      status={status}
      searchValue={searchValue}
      applicationInfo={applicationInfo}
      handleChangeSearch={handleChangeSearch}
    />
  )
}

const ApplicationSearchContentConnect = connect(
  (state: RootStateInterface) => ({
    applicationInfo: applicationInfoS(state),
  }),
  {
    getApplication: applicationInfoA.request,
    dropApplicationInfo: dropApplicationInfoA,
  },
)(ApplicationSearchContent)

routsManager.registerRoute({
  order: 0,
  rights,
  exact: false,
  isPrivate: true,
  showNavLink: false,
  path: '/application/search/:id',
  element: ApplicationSearchContentConnect,
})

routsManager.registerRoute({
  order: 0,
  rights,
  exact: false,
  isPrivate: true,
  showNavLink: false,
  path: '/application/search',
  element: ApplicationSearchContentConnect,
})
