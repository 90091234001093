import React from 'react'
import * as R from 'ramda'
import {
  Route,
} from 'react-router-dom'
import * as yup from 'yup'

import PrivateRoute from '../HOC/PrivateRoute'

import initialRouts from './routes'

class RoutsManager {
  constructor(initialRouts) {
    this.registeredPage = []
    this._init(initialRouts)
  }

  _init(initialRouts) {
    this.routeDataSchema = yup.object({
      order: yup.number(),
      // title: yup.,
      path: yup.mixed(),
      component: yup.object(),
      render: yup.object(),
      rights: yup.object({
        show: yup.mixed(),
        edit: yup.mixed(),
      }).required().default({
        show: '',
        edit: '',
      }),
      profile: yup.string().default('ALL'),
      showNavLink: yup.boolean(),
      isPrivate: yup.boolean().required(),
      exact: yup.boolean().required(),
      dropdown: yup.boolean(),
      id: yup.string(),
    })

    this.routes = initialRouts.map(route => this.routeDataSchema.validateSync(route))
  }

  // Регистрация маршрутов
  registerPage(route) { // old
    this.registeredPage.push(this.routeDataSchema.validateSync(route))
  }

  registerRoute(route) {
    this.registerPage(route)
  }

  // Инициализация маршрутов
  initRoutes() {
    this.registeredPage.forEach(page => {
      return this.routes.unshift(page);
    });
  }

  // Создание навигационных ссылок из зарегистрированных маршрутов
  createRoutesSet(rights) {
    if (!rights || rights.length === 0) {
      return this.routes.filter((route) => {
        return route.showNavLink && !route.rights.show;
      }).sort((prevRoute, nextRoute) => prevRoute.order - nextRoute.order)
    }
    return this.routes.filter((route) => {
      for (let i = 0; i < rights.length; i += 1) {
        if (
          route.showNavLink
          && (
            rights.find((right) => {
              return R.includes(right, route.rights.show)
            })
            || !route.rights.show
          )
        ) {
          return route
        }
      }
      return false
    }).sort((prevRoute, nextRoute) => prevRoute.order - nextRoute.order)
  }

  // Создание компонентов react-router по имеющимся зарегистрированным маршрутам
  getRoutes({
    isAuthenticated,
  }) {
    let i = 0
    return this.routes.map((route) => {
      i += 1
      const {
        isPrivate, exact, path, element, render, props,
      } = route
      if (isPrivate) {
        return PrivateRoute({ ...route, key: i, isAuthenticated })
      }
      return (
        <Route
          key={i}
          path={path}
          exact={exact}
          props={props}
          element={element || render}
          isAuthenticated={isAuthenticated}
        />
      )
    })
  }
}

const routsManager = new RoutsManager(initialRouts)

export default routsManager
