import * as R from 'ramda'
import { LOCALES } from './locales'

import * as routers from './messages/routers'
import * as different from './messages/different'
import * as application from './messages/application'

const changePath = (list: any, path: string): any => {
  let newList = {}
  R.forEachObjIndexed((value: any, key: any): any => {
    newList = R.assoc(`${path}.${key}`, value, newList)
  }, list)
  return newList
}

export const messages = {
  [LOCALES.ENGLISH]: {
    ...changePath(routers.en, 'routers'),
    ...changePath(different.en, 'different'),
    ...changePath(application.en, 'application'),
  },
  [LOCALES.RUSSIAN]: {
    ...changePath(routers.ru, 'routers'),
    ...changePath(different.ru, 'different'),
    ...changePath(application.ru, 'application'),
  },
}
