export const en = {
  create: 'Submit an application',
  create_title: 'New application',
  search: 'Find an application',
  'form.fullName': 'Full name',
  'form.photo': 'photo',
  'form.fullName_desc': 'Enter full name',
  'form.save': 'Send',
  'form.cancel': 'Cancel',
  'form.pass': 'Pass to organizations',
  'form.pass_desc': 'Enter organizations',
  'form.click_upload': 'Click here for upload photo',
  'form.click_upload_doc': 'Click here for upload document',
  'form.created_date': 'Creation date',
  'form.last_edit': 'Last change',
  'form.status': 'Status',
  'form.verified_f': 'Photo is not correctly. Please dowload another photo for this person ',
  'info.desc': 'Save the application number to check the current status, on the page: ',
  'info.title': 'Application number',
  'search.placeholder': 'Application number for search',
  'status.pend': 'Pending',
  'status.ready': 'Accepted',
  'status.decline': 'Declined',
  backToMenu: 'Back to menu',
  copy: 'Click to copy',
  'form.doc': 'Identity document',
  'form.file_format': 'file format: ',
  'form.file_desc.first': 'Select file',
  'form.file_desc.second': 'or drag it here',
}

export const ru = {
  create: 'Подать заявку',
  create_title: 'Новая заявка',
  search: 'Найти заявку',
  'form.fullName': 'ФИО',
  'form.photo': 'Фото',
  'form.fullName_desc': 'Фамилия имя (отчество)',
  'form.save': 'Отправить',
  'form.cancel': 'Отменить',
  'form.pass': 'Доступ в организации',
  'form.pass_desc': 'Введите организации',
  'form.click_upload': 'Нажмите здесь, чтобы загрузить фото',
  'form.click_upload_doc': 'Нажмите здесь, чтобы загрузить документ',
  'form.created_date': 'Дата создания',
  'form.last_edit': 'Последнее изменение',
  'form.status': 'Статус',
  'form.verified_f': 'Некорректное фото. Пожалуйста, загрузите другое фото для этого человека',
  'info.desc': 'Сохраните номер заявки для проверки актуального статуса, на странице: ',
  'info.title': 'Номер заявки ',
  'search.placeholder': 'Номер заявки для поиска',
  'status.pend': 'На рассмотрении',
  'status.ready': 'Принята',
  'status.decline': 'Отклонена',
  backToMenu: 'Назад к меню',
  copy: 'Нажмите, чтобы скопировать',
  'form.doc': 'Документ, удостоверяющий личность',
  'form.file_format': 'формат файла: ',
  'form.file_desc.first': 'Выберите файл',
  'form.file_desc.second': 'или перетащите его сюда',
}
