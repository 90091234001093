import { takeEvery, put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getAppVersionA, getUserDataA } from './actions';

import AuthAPI from '../../services/API/Auth'

/**
 * Получение данных пользователей
 * @param action
 */
function* getUserDataSaga(): SagaIterator {
  try {
    yield put(getUserDataA.success({}))
  } catch (error) {
    yield put(getUserDataA.failure(error as Error))
  }
}

/**
 * Получение версии приложения
*/
function* getAppVersionSaga(): SagaIterator {
  try {
    // eslint-disable-next-line camelcase
    const { changelog_version } = yield call([AuthAPI, AuthAPI.version])
    yield put(getAppVersionA.success(changelog_version))
  } catch (error) {
    yield put(getAppVersionA.failure(error as Error))
  }
}

export default function* (): SagaIterator {
  yield takeEvery(getUserDataA.request, getUserDataSaga)
  yield takeEvery(getAppVersionA.request, getAppVersionSaga)
}
