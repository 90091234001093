// Функция для вычисления высоты и ширины экрана и обозначения типа устройства (телефон, планшет или пк)
// Удобно использовать, когда необходимо визуализировать разные компоненты в зависимости от типа устройства.

import { useState, useEffect } from 'react';
import MobileDetect from 'mobile-detect'

export default function useWindowSize() {
  // инициализируем состояние с неопределенными шириной, высотой и типом экрана
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    typeDisplay: undefined,
    rotation: 'horizontal',
    defTypeDisplay: 'Desktop',
  });

  useEffect(() => {
    // Обработчик для вызова при изменении размера экрана
    function handleResize() {
      const fullInfo = new MobileDetect(window.navigator.userAgent)
      // Задаем ширину, высоту и тип экрана
      if (fullInfo.mobile() && !fullInfo.tablet()) {
        setWindowSize({
          ...windowSize,
          typeDisplay: 'Mobile',
          width: window.innerWidth,
          height: window.innerHeight,
          rotation: window.innerHeight > window.innerWidth ? 'vertical' : 'horizontal',
        })
      } else if (fullInfo.tablet()) {
        setWindowSize({
          ...windowSize,
          typeDisplay: 'Tablet',
          width: window.innerWidth,
          height: window.innerHeight,
          rotation: window.innerHeight > window.innerWidth ? 'vertical' : 'horizontal',
        })
      } else {
        setWindowSize({
          ...windowSize,
          typeDisplay: 'Desktop',
          width: window.innerWidth,
          height: window.innerHeight,
          rotation: window.innerHeight > window.innerWidth ? 'vertical' : 'horizontal',
        })
      }
    }
    // Прослушиваем событие
    window.addEventListener('resize', handleResize);

    handleResize();

    // Удаление прослушивателя события при очистке
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Пустой массив гарантирует, что эффект запускается только при монтировании (on mount)

  return windowSize;
}
