import moment from 'moment';
import { createReducer } from '@reduxjs/toolkit'
import {
  OFFLINE,
  ONLINE,
  GLOBAL_ERROR,
  REQUEST_ERROR,
  REQUEST_ERROR_OUT,
  CHANGE_DATE,
} from '../../actions';

const initialState = {
  isOnline: navigator.onLine,
  date: moment().format('DD.MM.YYYY'),
  globalError: false,
  errorData: null,
};

const reducer = createReducer(initialState, {
  [ONLINE]: (state) => {
    state.isOnline = !state.isOnline
    return state
  },
  [OFFLINE]: (state) => {
    state.isOnline = !state.isOnline
    return state
  },
  [REQUEST_ERROR]: (state, action) => {
    state.globalError = true
    state.errorData = action.payload
    return state
  },
  [GLOBAL_ERROR]: (state, action) => {
    state.globalError = true
    state.errorData = action.payload
    return state
  },
  [REQUEST_ERROR_OUT]: (state) => {
    state.errorData = null
    state.globalError = false
    return state
  },
  [CHANGE_DATE]: (state, action) => {
    state.date = moment(action.payload.date).format('DD.MM.YYYY')
    return state
  },
})

export default reducer
