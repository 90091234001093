import { createAction } from 'typesafe-actions';

export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const NOTIFICATION_WARNING = 'NOTIFICATION_WARNING';
export const NOTIFICATION_INFO = 'NOTIFICATION_INFO';
export const NOTIFICATION_CONFIRM = 'NOTIFICATION_CONFIRM';

export const notification = {
  show: createAction(NOTIFICATION_SHOW)<{message: string}>(),

  success: createAction(NOTIFICATION_SUCCESS)<{message: string}>(),

  error: createAction(NOTIFICATION_ERROR)<{message: string}>(),

  warning: createAction(NOTIFICATION_WARNING)<{message: string}>(),

  info: createAction(NOTIFICATION_INFO)<{message: string}>(),

  confirm: createAction(NOTIFICATION_CONFIRM)<any>(),
};

export const notificationReset = (id: any) => ({
  type: 'NOTIFICATION_RESET',
  payload: id,
});
