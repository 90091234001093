import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';

import './style.sass';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const [isView, setIsView] = useState(false)
  const navigate = useNavigate()
  const handleGoMain = () => {
    navigate('/users')
  }

  useEffect(() => {
    setTimeout(() => {
      setIsView(true)
    })
    return () => {
      setIsView(false)
    }
  }, [])

  if (!isView) return null

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, #FE8A00, #c86c00)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="20px" mt={3} mb={2}>
        <FormattedMessage id="different.not_found" />
      </Text>
      <Text color={'gray.500'} mb={6} fontSize="16px">
        <FormattedMessage id="different.not_found_desc" />
      </Text>

      <Button
        colorScheme="orange"
        bgGradient="linear(to-r, #FE8A00, #ec8000, #c86c00)"
        color="white"
        variant="solid"
        onClick={handleGoMain}
      >
        <FormattedMessage id="different.go_main" />
      </Button>
    </Box>
  );
}

export default NotFound;
