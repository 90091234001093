import { Map } from 'immutable';
import { createReducer, getType } from 'typesafe-actions';
import routsManager from '../../routes';
import { getAppVersionA, getUserDataA } from './actions';

const initialState = Map<any>({
  appVersion: '',
  routersSet: null,
  isFetching: false,
});

const reducer = createReducer(initialState, {
  [getType(getUserDataA.request)]: (state: any) => {
    return state
  },
  [getType(getUserDataA.success)]: (state: any) => {
    return state
    .set('routersSet', routsManager.createRoutesSet([]))
  },
  [getType(getAppVersionA.request)]: (state: any) => {
    return state
  },
  [getType(getAppVersionA.success)]: (state: any, action: any) => {
    return state
      .set('appVersion', action.payload)
  },
  [getType(getAppVersionA.failure)]: (state: any) => {
    return state
      .set('isFetching', false)
  },
})

export default reducer
