import React, { ReactElement, Suspense, useEffect, useState } from 'react';
import {
  Routes, useLocation, useNavigate,
} from 'react-router-dom'
import { createStandaloneToast, Spinner } from '@chakra-ui/react'
import * as R from 'ramda'
import { useCookies } from 'react-cookie';
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux';
import Layout from '../components/Blocks/Layout'
import routsManager from '../routes'
import { LOCALES } from '../utils/lang/locales';
import { messages } from '../utils/lang/messages';
import useWindowSize from '../utils/sizeWindow/windowSizeHook';
import { getAppVersionA, getUserDataA } from './User/actions';

const { ToastContainer } = createStandaloneToast()

type AppType = {
  getUserData: () => void
  getAppVersion: () => void
}

function App({
  getUserData,
  getAppVersion,
}: AppType): ReactElement {
  const [{ selectedLocale }, setCookie] = useCookies(['selectedLocale']);
  const [locale, setLocale] = useState<string>(selectedLocale || R.includes('ru', navigator.language) ? LOCALES.RUSSIAN : LOCALES.ENGLISH)
  const { typeDisplay } = useWindowSize()
  const navigate = useNavigate()
  const location = useLocation()

  const _init = () => {
    // Иницилизация маршрутов зарегистрированных страниц
    routsManager.initRoutes()
    getAppVersion()
    getUserData()
    if (R.isNil(sessionStorage.getItem('org_id')) || R.isEmpty(sessionStorage.getItem('org_id'))) {
      if (
        !R.test(/\/application\/search/, location.pathname)
        || !R.test(/\/application\/search\/[a-z0-9-]+/, location.pathname)
      ) navigate('/application/search')
    }
  }

  useEffect(() => {
    _init()
    if (R.isNil(selectedLocale) || R.isEmpty(selectedLocale)) {
      setCookie('selectedLocale', R.includes('ru', navigator.language) ? LOCALES.RUSSIAN : LOCALES.ENGLISH, { path: '/' })
    }
  }, [])

  useEffect(() => {
    setLocale(selectedLocale || LOCALES.RUSSIAN)
  }, [selectedLocale])

  return (
    <>
      <div className={`workspace${typeDisplay !== 'Desktop' ? '-mobile' : ''}`}>
        <IntlProvider defaultLocale={LOCALES.RUSSIAN} locale={locale} messages={messages[locale]}>
          <Suspense fallback={<Spinner />}>
            <Layout>
              <Routes>
                {routsManager.getRoutes({ isAuthenticated: true })}
              </Routes>
            </Layout>
          </Suspense>
        </IntlProvider>
      </div>
      <ToastContainer />
    </>
  );
}

export default connect(
  () => ({}),
  {
    getUserData: getUserDataA.request,
    getAppVersion: getAppVersionA.request,
  },
)(App);
