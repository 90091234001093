
export const CREATE_APPLICATION_REQUEST = 'application/create:request';
export const CREATE_APPLICATION_SUCCESS = 'application/create:success';
export const CREATE_APPLICATION_ERROR = 'application/create:error';

export const APPLICATION_INFO_REQUEST = 'application/get/info:request';
export const APPLICATION_INFO_SUCCESS = 'application/get/info:success';
export const APPLICATION_INFO_ERROR = 'application/get/info:error';

export const DROP_APPLICATION_INFO = 'application/drop/info'

export const CHANGE_VISIBLE_DRAWER = 'application/change/create/visibleDrawer'
