import React from 'react';
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { ChakraProvider } from '@chakra-ui/react';
import ru from 'date-fns/locale/ru'
import { registerLocale } from 'react-datepicker';
import { faker } from '@faker-js/faker'
import App from './containers/App';
import submitOnGlobalEvent from './services/Events/Global/eventSubscriber';
import reportWebVitals from './reportWebVitals';
import store, { history } from './store/configureStore'
import './style/normalize.css'
import './style/base.sass'
import './style/index.sass'
import './routes/init'
import './style/_fonts.sass';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ru', ru)
faker.setLocale('ru')
submitOnGlobalEvent(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <Router history={history}>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </Router>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
