import React, { ReactElement } from 'react'
import './style.sass'
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Input,
  Tooltip,
  Icon,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { AddIcon } from '@chakra-ui/icons'
import { BsAsterisk } from 'react-icons/bs'
import SpinnerLoading from '../../Common/Spinner'
import FileForm from '../../../utils/fileForm'

type ApplicationCreateProps = {
  name: string
  pass: string

  isOpenDrawer: boolean
  isFetching: boolean
  isDisabledSave: boolean

  size: any
  firstField: any

  handleSave: () => void
  handleOpenDrawer: () => void
  handleCloseDrawer: () => void
  handleUploadDoc: (data: any) => void
  handleUploadImg: (data: any) => void
  handleChangePass: (data: any) => void
  handleChangeName: (data: any) => void
}

function ApplicationCreate({
  name,
  pass,
  size,
  isFetching,
  firstField,
  isOpenDrawer,
  isDisabledSave,

  handleSave,
  handleUploadDoc,
  handleUploadImg,
  handleOpenDrawer,
  handleChangePass,
  handleChangeName,
  handleCloseDrawer,
}: ApplicationCreateProps): ReactElement {
  return (
    <>
      <FormattedMessage id="application.create">
        {
        txt => (size.typeDisplay === 'Desktop' ? (
          <Tooltip
            hasArrow
            bg="gray.500"
            arrowSize={12}
            placement="top"
            label={String(txt[0])}
            className="drawer-trigger__btn-tooltip"
          >
            <IconButton
              aria-label="create"
              className="drawer-trigger__btn"
              onClick={handleOpenDrawer}
              icon={<AddIcon color="white" />}
            />
          </Tooltip>
        ) : (
          <Tooltip
            hasArrow
            bg="gray.500"
            arrowSize={12}
            placement="left"
            label={String(txt[0])}
            className="drawer-trigger__btn-tooltip"
          >
            <IconButton
              aria-label="create"
              className="drawer-trigger__btn-mob"
              onClick={handleOpenDrawer}
              icon={<AddIcon color="white" />}
            />
          </Tooltip>
        ))}
      </FormattedMessage>
      {size.typeDisplay === 'Desktop' ? (
        <Modal
          size="lg"
        // placement="right"
          isOpen={isOpenDrawer}
          onClose={handleCloseDrawer}
          initialFocusRef={firstField}
          returnFocusOnClose={false}
        >
          <ModalOverlay />
          <ModalContent className="application-create__content">
            <ModalCloseButton />
            <ModalHeader borderBottomWidth="1px">
              <FormattedMessage id="application.create_title" />
            </ModalHeader>
            <ModalBody className="application-create__content-body">
              <div className="field">
                <div className="title"><FormattedMessage id="application.form.photo" /><Icon as={BsAsterisk} w={2} h={2} color="red.500" /></div>
                <FileForm
                  isPreview
                  handleChange={handleUploadImg}
                  formats={'.png, .jpg, .jpeg'}
                />
              </div>
              {/* <Box className="image" onClick={() => handleClickFile(fileRef)}>
                {!image && <div className="text"><FormattedMessage id="application.form.click_upload" /></div>}
                {image && (
                <div
                  className="background-hover"
                  style={{
                      opacity: isHoverImage ? 1 : 0,
                    }}
                  onMouseEnter={() => setIsHoverImage(true)}
                  onMouseLeave={() => setIsHoverImage(false)}
                >
                  <div className="text"><FormattedMessage id="application.form.click_upload" /></div>
                </div>
            )}
              </Box>
              <input
                className="file"
                type="file"
                ref={fileRef}
                accept=".png, .jpg, .jpeg"
                onChange={handleUploadImg}
              /> */}
              <div
                className="fields"
              >
                <div className="field">
                  <div className="title"><FormattedMessage id="application.form.fullName" /><Icon as={BsAsterisk} w={2} h={2} color="red.500" /></div>
                  <FormattedMessage id="application.form.fullName_desc">
                    {
                txt => (
                  <Input
                    value={name}
                    ref={firstField}
                    placeholder={String(txt[0])}
                    _placeholder={{ opacity: 0.4 }}
                    // borderColor="#F0F1F7"
                    onChange={({ target }: any):void => handleChangeName(target.value)}
                  />
                )
              }
                  </FormattedMessage>
                </div>
                <div className="field">
                  <div className="title"><FormattedMessage id="application.form.pass" /><Icon as={BsAsterisk} w={2} h={2} color="red.500" /></div>
                  <FormattedMessage id="application.form.pass_desc">
                    {
                txt => (
                  <Input
                    value={pass}
                    placeholder={String(txt[0])}
                    _placeholder={{ opacity: 0.4 }}
                    // borderColor="#F0F1F7"
                    onChange={({ target }: any):void => handleChangePass(target.value)}
                  />
                )
              }
                  </FormattedMessage>
                </div>
                <div className="field">
                  <div className="title"><FormattedMessage id="application.form.doc" /></div>
                  <FileForm
                    isDeleteFile
                    formats={'.png, .jpeg, .pdf'}
                    handleChange={handleUploadDoc}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter borderTopWidth="1px" as={ButtonGroup} spacing="4" className="application-create__content-footer">
              <FormattedMessage id="application.form.save">
                {
              txt => (
                <FormattedMessage id={'different.req_fields'}>
                  { text => (
                    <Tooltip
                      hasArrow
                      fontWeight="bold"
                      borderRadius={4}
                      textAlign="center"
                      label={String(text[0])}
                      isDisabled={!isDisabledSave}
                    >
                      <Button isDisabled={isDisabledSave} onClick={handleSave}>{String(txt[0])}</Button>
                    </Tooltip>
              )}
                </FormattedMessage>
              )}
              </FormattedMessage>
              <FormattedMessage id="application.form.cancel">
                {
              txt => (
                <Button onClick={handleCloseDrawer}>{String(txt[0])}
                </Button>
              )}
              </FormattedMessage>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer
          size="lg"
          placement="right"
          isOpen={isOpenDrawer}
          onClose={handleCloseDrawer}
          initialFocusRef={firstField}
          returnFocusOnClose={false}
        >
          <DrawerOverlay />
          <DrawerContent className="application-create__content">
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              <FormattedMessage id="application.create_title" />
            </DrawerHeader>
            <DrawerBody className="application-create__content-body">
              <div className="field">
                <div className="title"><FormattedMessage id="application.form.photo" /><Icon as={BsAsterisk} w={2} h={2} color="red.500" /></div>
                <FileForm
                  isPreview
                  handleChange={handleUploadImg}
                  formats={'.png, .jpg, .jpeg'}
                />
              </div>
              <div
                className="fields"
              >
                <div className="field">
                  <div className="title"><FormattedMessage id="application.form.fullName" /><Icon as={BsAsterisk} w={2} h={2} color="red.500" /></div>
                  <FormattedMessage id="application.form.fullName_desc">
                    {
                      txt => (
                        <Input
                          value={name}
                          ref={firstField}
                          placeholder={String(txt[0])}
                          _placeholder={{ opacity: 0.4 }}
                          // borderColor="#F0F1F7"
                          onChange={({ target }: any):void => handleChangeName(target.value)}
                        />
                      )
                    }
                  </FormattedMessage>
                </div>
                <div className="field">
                  <div className="title"><FormattedMessage id="application.form.pass" /><Icon as={BsAsterisk} w={2} h={2} color="red.500" /></div>
                  <FormattedMessage id="application.form.pass_desc">
                    {
                      txt => (
                        <Input
                          value={pass}
                          placeholder={String(txt[0])}
                          _placeholder={{ opacity: 0.4 }}
                          // borderColor="#F0F1F7"
                          onChange={({ target }: any):void => handleChangePass(target.value)}
                        />
                      )
                    }
                  </FormattedMessage>
                </div>
                <div className="field">
                  <div className="title"><FormattedMessage id="application.form.doc" /></div>
                  <FileForm
                    isDeleteFile
                    formats={'.png, .jpeg, .pdf'}
                    handleChange={handleUploadDoc}
                  />
                </div>
              </div>
            </DrawerBody>
            <DrawerFooter borderTopWidth="1px" as={ButtonGroup} spacing="4" className="application-create__content-footer">
              <FormattedMessage id="application.form.save">
                {
                  txt => (
                    <FormattedMessage id={'different.req_fields'}>
                      { text => (
                        <Tooltip
                          hasArrow
                          fontWeight="bold"
                          borderRadius={4}
                          textAlign="center"
                          label={String(text[0])}
                          isDisabled={!isDisabledSave}
                        >
                          <Button isDisabled={isDisabledSave} onClick={handleSave}>{String(txt[0])}</Button>
                        </Tooltip>
                      )}
                    </FormattedMessage>
              )}
              </FormattedMessage>
              <FormattedMessage id="application.form.cancel">
                {
              txt => (
                <Button onClick={handleCloseDrawer}>{String(txt[0])}
                </Button>
              )}
              </FormattedMessage>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
      <SpinnerLoading
        isFetching={isFetching}
      />
    </>
  )
}

export default ApplicationCreate
