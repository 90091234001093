import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_SUCCESS,
  CREATE_APPLICATION_ERROR,
  APPLICATION_INFO_REQUEST,
  APPLICATION_INFO_SUCCESS,
  APPLICATION_INFO_ERROR,
  DROP_APPLICATION_INFO,
  CHANGE_VISIBLE_DRAWER,
} from './types';

/** Создание заявки */
export const createApplicationA = createAsyncAction(
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_SUCCESS,
  CREATE_APPLICATION_ERROR,
)<any, string, Error>()

/** Информация о заявке */
export const applicationInfoA = createAsyncAction(
  APPLICATION_INFO_REQUEST,
  APPLICATION_INFO_SUCCESS,
  APPLICATION_INFO_ERROR,
)<any, any, Error>()

export const dropApplicationInfoA = createAction(
  DROP_APPLICATION_INFO,
)<undefined>()

export const changeVisibleDrawerA = createAction(
  CHANGE_VISIBLE_DRAWER,
)<boolean>()

