import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import global from '../containers/Global/reducer'
// import { LOG_OUT } from '../containers/Global/types';
import userData from '../containers/User/reducer';
import application from '../containers/Application/reducer'
import notification from '../containers/Blocks/Notification/reducer';

const {
  routerReducer,
} = createReduxHistoryContext({ history: createBrowserHistory() });

export const rootReducer = (): any => (state: any, action: any) => {
  const appReducer = combineReducers({
    router: routerReducer,
    userData,
    global,
    application,
    notification,
  })

  // if (action.type === LOG_OUT) {
  //   // eslint-disable-next-line no-param-reassign
  //   state = undefined
  // }
  return appReducer(state, action)
}
