export const statusList = [
  {
    label: 'application.status.pend',
    value: 'pending',
    style: {
      color: '#FFB74D',
    },
  },
  {
    label: 'application.status.ready',
    value: 'ready',
    style: {
      color: '#29CC97',
    },
  },
  {
    label: 'application.status.decline',
    value: 'decline',
    style: {
      color: '#F12B2C',
    },
  },
]
