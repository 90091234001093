import React, { ReactElement } from 'react'
import './style.sass'
import { FormattedMessage } from 'react-intl'
import * as R from 'ramda'
import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import moment from 'moment'
import ApplicationCreateContent from '../../../containers/Application/Create'

type ApplicationSearchProps = {
  searchValue: string

  status: any
  applicationInfo: any

  handleChangeSearch: (data: any) => void
}

function ApplicationSearch({
  status,
  searchValue,
  applicationInfo,

  handleChangeSearch,
}: ApplicationSearchProps): ReactElement {
  return (
    <Box
      className="application-search"
      style={{
        gridTemplateAreas: R.isNil(sessionStorage.getItem('org_id')) || R.isEmpty(sessionStorage.getItem('org_id'))
          ? '\'b\' \'d\'' : '\'a b\' \'c d\'',
        gridTemplateColumns: R.isNil(sessionStorage.getItem('org_id')) || R.isEmpty(sessionStorage.getItem('org_id'))
          ? '1fr' : 'max-content minmax(200px, 600px)',
        paddingRight: R.isNil(sessionStorage.getItem('org_id')) || R.isEmpty(sessionStorage.getItem('org_id'))
          ? '0' : '42px',
      }}
    >
      <ApplicationCreateContent />
      <FormattedMessage id="application.search.placeholder">
        {
            txt => (
              <InputGroup className="search-field">
                <InputLeftElement
                  pointerEvents="none"
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                    <SearchIcon color="#718096" />
                  )}
                />
                <Input
                  value={searchValue}
                  type="search"
                  placeholder={String(txt[0])}
                  onChange={handleChangeSearch}
                />
                {/* {!R.isEmpty(searchValue) && (
                  <InputRightElement
                    cursor="pointer"
                    onClick={() => handleChangeSearch({ target: { value: '' } })}
                  // eslint-disable-next-line react/no-children-prop
                    children={<SmallCloseIcon />}
                  />
                )} */}
              </InputGroup>
            )
          }
      </FormattedMessage>
      {!R.isEmpty(applicationInfo) && !R.isNil(applicationInfo) && (
        <div className="application-card">
          <div className="status-text">
            <FormattedMessage id="application.form.status" />:&nbsp;
            {!R.isEmpty(status) && (<div className="text" style={{ color: status.style.color }}><FormattedMessage id={status.label} /></div>)}
          </div>
          <div className="name">
            <FormattedMessage id="application.form.fullName" />: {applicationInfo.name}
          </div>
          <div className="pass">
            <FormattedMessage id="application.form.pass" />: {applicationInfo.pass_to_orgs}
          </div>
          <div className="create-date">
            <FormattedMessage id="application.form.created_date" />: {moment(applicationInfo.created_at).format('DD.MM.YYYY HH:mm').toString()}
          </div>
          <div className="update-date">
            <FormattedMessage id="application.form.last_edit" />: {moment(applicationInfo.updated_at).format('DD.MM.YYYY HH:mm').toString()}
          </div>
        </div>
      )}
    </Box>
  )
}

export default ApplicationSearch
