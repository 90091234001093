import MainAPI from '../main.api';

class ApplicationAPI extends MainAPI {
  async createApplication(data: FormData): Promise<Response> {
    return this.postData({ url: `/claims/${sessionStorage.getItem('org_id')}`, data, params: { headers: { 'Content-Type': 'multipart/form-data' } } })
  }

  async getApplication(id: string): Promise<Response> {
    return this.getData(`/claims/${id}`)
  }
}

export default new ApplicationAPI();
