import React, { ReactElement } from 'react'
import { useCookies } from 'react-cookie'
import { connect } from 'react-redux'
import ru from '../../../img/ru-flag.svg'
import en from '../../../img/en-flag.svg'
import './style.sass'
import logo from '../../../img/logo.svg'
import { NYLogo } from '../../../utils/NewYearScript'

function Header(): ReactElement {
  const [cookie, setCookie] = useCookies(['isCollapseBar', 'selectedLocale'])

  const handleChangeLang = (lang: string) => {
    setCookie('selectedLocale', lang, { path: '/' })
  }

  return (
    <div className="header">
      <div className="logo">
        {NYLogo(<img src={logo} alt="logo" />, { transform: 'translateX(13px)' })}
        NinaFace
      </div>
      <div className="userMenu">
        <div className="flags">
          <img
            src={ru}
            alt="flag"
            onClick={() => handleChangeLang('ru')}
            className={cookie.selectedLocale === 'ru' ? 'active' : ''}
          />
          <img
            src={en}
            alt="flag"
            onClick={() => handleChangeLang('en')}
            className={cookie.selectedLocale === 'en' ? 'active' : ''}
          />
        </div>
      </div>
    </div>

  )
}

export default connect(
  (state: RootStateInterface) => ({
    state,
  }),
)(Header)
