import { CircularProgress } from '@chakra-ui/react';
import React from 'react';

import './style.sass';

function SpinnerLoading({
  isFetching,
}: any) {
  return (
    <div
      className="spinner-container"
      style={{
        opacity: isFetching ? '1' : '0',
        zIndex: isFetching ? '10000' : '-100',
      }}
    >
      <div className="spinner-border text-primary" role="status">
        <CircularProgress
          size="140px"
          color="#e77f00"
          thickness="3px"
          isIndeterminate
          className="app__loader"
        >
          {/* <CircularProgressLabel className="desc"><FormattedMessage id="different.app_preloader" /></CircularProgressLabel> */}
        </CircularProgress>
      </div>
    </div>
  )
}

export default SpinnerLoading;
