import { Map } from 'immutable';
import { createReducer, getType } from 'typesafe-actions';
import { applicationInfoA, changeVisibleDrawerA, createApplicationA, dropApplicationInfoA } from './actions';

const initialState = Map<any>({
  isFetching: false,
  applicationID: '',
  applicationInfo: {},
  isVisibleDrawer: !localStorage.getItem('application'),
});

const reducer = createReducer(initialState, {
  [getType(createApplicationA.request)]: (state: any) => {
    return state
      .set('isFetching', true)
  },
  [getType(createApplicationA.success)]: (state: any, action: any) => {
    return state
      .set('isFetching', false)
      .set('applicationID', action.payload)
  },
  [getType(createApplicationA.failure)]: (state: any) => {
    return state
      .set('isFetching', false)
  },
  [getType(applicationInfoA.request)]: (state: any) => {
    return state
      // .set('isFetching', true)
  },
  [getType(applicationInfoA.success)]: (state: any, action: any) => {
    return state
      .set('isFetching', false)
      .set('applicationInfo', action.payload)
  },
  [getType(applicationInfoA.failure)]: (state: any) => {
    return state
      .set('isFetching', false)
      .set('applicationInfo', null)
  },
  [getType(dropApplicationInfoA)]: (state: any) => {
    return state
      .set('applicationInfo', {})
  },
  [getType(changeVisibleDrawerA)]: (state: any, action: any) => {
    return state
      .set('isVisibleDrawer', action.payload)
  },
})

export default reducer
