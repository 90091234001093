// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
import moment from 'moment';
import snowflake from '../../img/snowflake.svg'
import logoNY from '../../img/logoNY.svg'

export function IsNYDate() {
  if ((moment().get('month') === 11 && moment().get('date') >= 6)
  || (moment().get('month') === 0 && moment().get('date') <= 31)) {
    return true
  }
  return false
}

export function NYLogo(icon, style) {
  if ((moment().get('month') === 11 && moment().get('date') >= 6)
  || (moment().get('month') === 0 && moment().get('date') <= 31)) {
    return <img src={logoNY} alt="logo" style={style} />
  }
  return icon
}

export function NewYearScript() {
  if (
    (moment().get('month') === 11 && moment().get('date') >= 6)
    || (moment().get('month') === 0 && moment().get('date') <= 31)
  ) {
    const elemWorkspace = document.getElementsByClassName('workspace')[0]
    if (elemWorkspace !== undefined) {
      elemWorkspace.style.backgroundImage = `url(${snowflake})`
      elemWorkspace.style.backgroundRepeat = 'repeat'
    }
    const elemWorkspaceMob = document.getElementsByClassName('workspace-mobile')[0]
    if (elemWorkspaceMob !== undefined) {
      elemWorkspaceMob.style.backgroundImage = `url(${snowflake})`
      elemWorkspaceMob.style.backgroundRepeat = 'repeat'
    }
  }
}
