import { createAction } from 'typesafe-actions'

export const ONLINE = 'ONLINE';
export const OFFLINE = 'OFFLINE';
export const CHANGE_DATE = 'CHANGE_DATE';
export const GLOBAL_ERROR = 'GLOBAL_ERROR';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const REQUEST_ERROR_OUT = 'REQUEST_ERROR_OUT';

export const changeDate = createAction(CHANGE_DATE)<any>

export const globalErrorCatcher = createAction(GLOBAL_ERROR)<any>

export function requestError(data: any) {
  return (dispatch: any) => {
    dispatch({
      type: REQUEST_ERROR,
      payload: data,
    });
    setTimeout(() => dispatch({
      type: REQUEST_ERROR_OUT,
    }), 5000);
  };
}
